import React, { useRef, useState } from 'react';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { PageModel } from 'client/data/models/page';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { getCreativeId } from 'client/engagement-handlers/helper';
import { fireAdLoadTracking } from 'client/engagement-handlers/site-served-ads-engagement-handler/site-served-ads-engagement-handler';
import { TrackingHandler } from 'client/tracking/handler';

import {
  isEmptyCreativeConfig,
  trackAdZerkClick,
  trackAdZerkImpression,
} from 'site-modules/shared/components/native-ad/utils/utils';
import { WEBSITE_CLICKOUT_CUSTOM } from 'site-modules/shared/constants/ads';
import { ImpressionTracker } from 'site-modules/shared/components/impression-tracker/impression-tracker';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { get, uniq } from 'lodash';
import { logger } from 'client/utils/isomorphic-logger';

function siteServedAdTrackingWrapperComponent(WrappedComponent) {
  function SiteServedAdTrackingWrapper(props) {
    const { position, creativeConfig, slotRenderEndListener, visitorId, sessionId, page, legacy, ...restProps } = props;
    const card = useRef();
    const [clickCorrelatorTs, setClickCorrelatorTs] = useState(0);

    function adClickTrackingListener({
      eventTrackingId = WEBSITE_CLICKOUT_CUSTOM,
      customLineItemId,
      creativeConfigId,
    } = {}) {
      const {
        id,
        creativeConfigData: { lineItemId, siteServedCreativeId },
      } = creativeConfig;
      const creativeId = getCreativeId(card.current);
      trackAdZerkClick({ ...props, creativeId, clickCorrelatorTs });
      TrackingHandler.fireEvent(
        eventTrackingId,
        {},
        {
          creative_id: creativeId,
          line_item_id: customLineItemId || lineItemId,
          site_served_creative_id: siteServedCreativeId,
          creative_config_id: creativeConfigId || id,
          action_cause: 'link_click',
          click_correlator_ts: clickCorrelatorTs,
          position,
        }
      );
      const adNamesContainer = document.querySelector('[data-ad-names]');
      if (adNamesContainer) {
        const adClicked = get(adNamesContainer, 'dataset.adClicked', '').split(',');
        adNamesContainer.dataset.adClicked = uniq([...adClicked, creativeId]).join();
      }
    }

    function adLoadTrackingListener(event, trackingValue, correlatorTs, customLineItemId, customFields) {
      const {
        creativeConfigData: { lineItemId },
      } = creativeConfig;

      // get clickcorrelatorts from the ad slot
      const correlatorTsValue = (event && event.slot.getTargetingMap().clickcorrelatorts) || correlatorTs;

      fireAdLoadTracking(
        trackingValue,
        card.current,
        correlatorTsValue,
        position,
        customLineItemId || lineItemId,
        customFields
      );
      if (slotRenderEndListener && typeof slotRenderEndListener === 'function') {
        slotRenderEndListener(event);
      }
      setClickCorrelatorTs(correlatorTsValue);
    }

    if (isEmptyCreativeConfig(creativeConfig)) {
      return null;
    }

    const creativeId = getCreativeId(card.current);
    let adzerkImpressionUrl;
    try {
      adzerkImpressionUrl = trackAdZerkImpression({ ...props, creativeId, clickCorrelatorTs });
    } catch (error) {
      logger('error', `Invalid data: ${JSON.stringify(props.creativeConfig || {})}`);
      throw error;
    }

    const adTrackers = (
      <RenderWhenViewable>
        <div style={{ position: 'absolute', width: '1px', height: '1px' }}>
          <ImpressionTracker src={adzerkImpressionUrl} />
        </div>
      </RenderWhenViewable>
    );

    return (
      <FeatureFlag name="siteServedAds">
        <WrappedComponent
          {...restProps}
          position={position}
          creativeConfig={creativeConfig}
          slotRenderEndListener={slotRenderEndListener}
          adClickTrackingListener={adClickTrackingListener}
          adLoadTrackingListener={adLoadTrackingListener}
          adRef={card}
          adTrackers={adTrackers}
        />
      </FeatureFlag>
    );
  }

  SiteServedAdTrackingWrapper.displayName = `SiteServedAdTrackingWrapper(${WrappedComponent.name})`;
  return SiteServedAdTrackingWrapper;
}

const stateToPropsConfig = {
  visitorId: bindToPath('id', VisitorModel),
  sessionId: bindToPath('session', VisitorModel),
  page: bindToPath('page', PageModel),
  legacy: bindToPath('legacy', PageModel),
};

export const siteServedAdTrackingWrapper = WrappedComponent =>
  connectToModel(siteServedAdTrackingWrapperComponent(WrappedComponent), stateToPropsConfig);
