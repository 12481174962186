import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, uniq } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { VisitorModel } from 'client/data/models/visitor';
import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import { isAdEnabledByDma } from 'client/site-modules/shared/constants/ads';
import {
  getAdParamsByVehicle,
  getCreativeConfigAdPathByVehicle,
  isEmptyCreativeConfig,
  transformCreativeConfig,
} from 'client/site-modules/shared/components/native-ad/utils/utils';
import { getIvtClassName } from 'client/utils/get-ias-data';
import { REQUIRED_PARAMS } from 'site-modules/shared/components/native-ad/utils/constants';

/**
 * HOC for making decision what kind of ad should be displayed based on various data
 * @param {function} WrappedComponent
 * @returns {SiteServedAdWrapper}
 */
export function siteServedAdWrapper(WrappedComponent) {
  class SiteServedAdWrapper extends Component {
    componentDidMount() {
      const adNamesContainer = document.querySelector('[data-ad-names]');
      if (adNamesContainer) {
        const adLoaded = get(adNamesContainer, 'dataset.adRendered', '').split(',');
        adNamesContainer.dataset.adRendered = uniq([...adLoaded, this.props.adType.AD_NAME]).join();
      }
    }

    shouldComponentUpdate(nextProps) {
      const { currentVehicle, vehicle, creativeConfig } = nextProps;
      if ((!!currentVehicle || !!vehicle) && !!creativeConfig) {
        return true;
      }
      if (!isEmpty(vehicle)) {
        // e.g srp page, when switch from a specific vehicle to any vehicle
        const makeNiceName = get(vehicle, 'make.niceName');
        return !makeNiceName;
      }
      return false;
    }

    render() {
      const {
        creativeConfig = null,
        vehicle,
        currentVehicle,
        siteServedAdsEnabled,
        visitorLocation,
        showOnlyDFP,
        showOnlySiteServed = false,
        visitor,
        isEnableIAS,
      } = this.props;

      const { breakpoints, currentBreakpoint, pageLocation, ...otherProps } = this.props;

      // ad unit will be displayed for specific breakpoints, waiting for resolve of the current breakpoint
      if (!isEmpty(breakpoints) && !currentBreakpoint) {
        return null;
      }
      if (!isEmpty(breakpoints) && currentBreakpoint && !breakpoints[currentBreakpoint]) {
        return null;
      }

      // pending configuration
      // we should resolve creative config before rendering ads. This helps to avoid unnecessary re-renders.
      if (!showOnlyDFP && siteServedAdsEnabled && creativeConfig === null) {
        return null;
      }

      const ivtClassName = getIvtClassName(visitor, isEnableIAS);

      const excludedFromSiteServed =
        isEmptyCreativeConfig(creativeConfig) ||
        get(creativeConfig, 'excluded', false) ||
        !isAdEnabledByDma(visitorLocation);

      const canUseDFPFallback = excludedFromSiteServed || !siteServedAdsEnabled;
      const useDFPFallback = showOnlySiteServed === true ? false : canUseDFPFallback;

      /*
      vehicle - vehicle is from page context, it is general case
      currentVehicle -  vehicle is from component that is passed directly to ad unit
      */
      return (
        <WrappedComponent
          {...otherProps}
          vehicle={currentVehicle || vehicle}
          withDFPFallback={useDFPFallback}
          withSiteServed={!canUseDFPFallback}
          ivtClassName={ivtClassName}
        />
      );
    }
  }
  SiteServedAdWrapper.displayName = `WithSiteServedAdWrapper(${WrappedComponent.name})`;
  return SiteServedAdWrapper;
}

export const getSiteServedAdPath = params => {
  const {
    mobile,
    adType,
    vehicle,
    currentVehicle,
    creativeConfigPath,
    showOnlyDFP,
    siteServedAdsEnabled,
    pageLocation,
    adsSpaMode,
  } = params;

  /*
  vehicle - vehicle is from page context, it is general case
  currentVehicle -  vehicle is from component that is passed directly to ad unit
  */

  const targetVehicle = currentVehicle || vehicle;
  if (!siteServedAdsEnabled || showOnlyDFP) return null;

  const vehicleParams = getAdParamsByVehicle(targetVehicle);
  // vehicle params should be defined for given ad type
  const isDefined = REQUIRED_PARAMS[adType.AD_NAME].some(param => vehicleParams[param] !== 'none');
  if (!isDefined) {
    return null;
  }

  const adPath = `${creativeConfigPath ||
    getCreativeConfigAdPathByVehicle(mobile, targetVehicle, pageLocation, adsSpaMode)}["${adType.AD_NAME}"]`;
  return adPath;
};

const stateToPropsConfig = {
  creativeConfig: bindToPath(
    ({
      mobile,
      adType,
      vehicle,
      currentVehicle,
      creativeConfigPath,
      showOnlyDFP,
      siteServedAdsEnabled,
      pageLocation,
      adsSpaMode,
    }) =>
      getSiteServedAdPath({
        mobile,
        adType,
        vehicle,
        currentVehicle,
        creativeConfigPath,
        showOnlyDFP,
        siteServedAdsEnabled,
        pageLocation,
        adsSpaMode,
      }),
    NativeAdsCreativeConfigModel,
    transformCreativeConfig
  ),
  ads: bindToPath('ads', PageModel),
  visitorLocation: bindToPath('location', VisitorModel),
};

const mapStateToProps = state => ({
  vehicle: get(state, 'pageContext.vehicle'),
  siteServedAdsEnabled: state.featureFlags.siteServedAds,
  currentBreakpoint: get(state, 'pageContext.breakpointDeprecated2'),
  visitor: state.visitor,
  vehicleContextChange: get(state, 'pageContext.page.options.vehicleContextChange', false),
  pageLocation: get(state, 'pageContext.location'),
  adsSpaMode: get(state, 'pageContext.page.options.adsSpaMode', false),
  isEnableIAS: state.featureFlags.enableIAS,
});

export const propsAreEqual = (propsPrev, propsNext) => isEmpty(propsNext.vehicle);

export const withSiteServedAdWrapper = WrappedComponent =>
  connect(mapStateToProps)(
    memo(connectToModel(siteServedAdWrapper(WrappedComponent), stateToPropsConfig), propsAreEqual)
  );
